import { useAppDispatch } from '../../app/hooks';
import { ReactNode, useEffect } from 'react';
import { logout } from '../../app/api/actions';
import { useSelector } from 'react-redux';
import Message from '../organism/Message';
import Loader from '../organism/Loader';
import { selectorAuthUser, selectorTokenPresent } from '../../app/api/selector';
import { Button, makeStyles } from '@material-ui/core';
import { authOtpSetup, authOtpStartup } from '../../app/auth-otp/actions';
import { selectorAuthOtpError, selectorAuthOtpStatus } from '../../app/auth-otp/selector';
import { authWebSetup, authWebStartup } from '../../app/auth-web/actions';
import { selectorAuthWebResponse, selectorAuthWebStatus } from '../../app/auth-web/selector';
import AuthWeb from './AuthWeb';
import Signup from './Signup';
import VoicemeRecorder from '../organism/recorder/VoicemeRecorder';
import Enroll from './Enroll';


const useStyles = makeStyles((theme) => ({
    itemHalf: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: "35%",
    },
  }));
interface Prop {
    children?: ReactNode,
}
function VoiceSignup({children} : Prop) {
    const dispatch = useAppDispatch()
    const tokenPresent = useSelector(selectorTokenPresent)
    const handleVoiceLogin = (request: {email:string, submitButton: string}) => {
        if(request.submitButton == "OTP") {
            dispatch(authOtpSetup(request))
        } else if(request.submitButton == "WEB") {
            dispatch(authWebSetup(request))
        }
    }
    const statusOtp = useSelector(selectorAuthOtpStatus)
    const statusWeb = useSelector(selectorAuthWebStatus)
    const responseWeb = useSelector(selectorAuthWebResponse)
    const errorOtp = useSelector(selectorAuthOtpError)
    const reset = () => {
        dispatch(authOtpStartup())
        dispatch(authWebStartup())
    }
    const logoutAndReset = () => {
        dispatch(logout())
        reset()
    }
    useEffect(() => {
        reset()
    }, [dispatch])
    const user = useSelector(selectorAuthUser)
    const style = useStyles()
    if(tokenPresent) {
        return (<Enroll></Enroll>)
    }
    if(statusOtp == 'loading-phrase' || statusWeb == 'loading-phrase') {
        return <Loader></Loader>
    }
    if(statusOtp == 'request-user' && statusWeb != 'request-user') {
        const retryWeb = () => {
            if(responseWeb.email !== undefined && responseWeb.max_attempts && responseWeb.max_attempts > 0)
                dispatch(authWebSetup({email: responseWeb.email, session_id: responseWeb.session_id}))
            else reset()
        }
        return <AuthWeb okComplete={reset} okOnError={retryWeb}></AuthWeb>
    }
    if(statusOtp == 'auth-error-auth') {
        if (errorOtp)
            return (<Message title={`${errorOtp.code}: ${errorOtp.detail}`} onClick={reset}/>);
        else
            return (<Message title={`Uknown error, login failed.`} onClick={reset}/>);
    }
    return <Signup></Signup>
}
export default VoiceSignup;

import { ReactNode } from 'react';
import { AddressType } from '../../app/api/types';
import { Grid, Typography } from '@mui/material';
import { Paper, makeStyles } from '@material-ui/core';



interface Prop {
    address: AddressType,
    children?: ReactNode,
    hasPayment: boolean,
}

const useStyles = makeStyles((theme) => ({
    // Define your styles here
    addressContainer: {
        // Add your styles for the container
        // Example:
        backgroundColor: 'lightgray',
        padding: theme.spacing(2),
      },
    fieldContainer: {
        // Add your styles for the container
        // Example:
        backgroundColor: 'lightgray',
        padding: theme.spacing(0),
    },
    label: {
      // Add your styles for Typography components
      // Example:
      border: "1px",
      backgroundColor: theme.palette.type === 'dark' ? '#1A2027' : '#fff',
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    text: {
      // Add your styles for Typography components
      // Example:
      border: "1px",
      backgroundColor: theme.palette.type === 'dark' ? '#1A2027' : '#fff',
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    payment: {
        textAlign: "center",
    },
    noPayment: {
        textAlign: "center",
        color: "red",
        style: "bold",
    }
  }));

  function Item({label, children}: {label: string, children: string}) {
    const style = useStyles()
    return (
        <Grid container spacing={1} className={style.fieldContainer}>
            <Grid item xs={4}>
                <Typography className={style.label} >{label}</Typography>            
            </Grid>
            <Grid item xs={8}>
                <Typography className={style.text} >{children || "\u00A0"}</Typography>            
            </Grid>
        </Grid>
    )
  }
  
function FormAddress({  address, hasPayment, children }: Prop) {
    const style = useStyles()
    return (
        <Grid container spacing={1} className={style.addressContainer}>
            <Grid item xs={12}>
                <Paper className={hasPayment ? style.payment : style.noPayment} >{hasPayment ? "Payment is setup" : "Payment not setup"}</Paper>            
            </Grid>
            {address && <>
                <Grid key="address-city" item xs={12}>
                    <Item label="City" >{address.city}</Item>            
                </Grid>
                <Grid key="address-country" item xs={12}>
                    <Item label="Country" >{address.country}</Item>            
                </Grid>
                <Grid key="address-line-1" item xs={12}>
                    <Item label="Line 1" >{address.line1}</Item>            
                </Grid>
                <Grid key="address-line-2" item xs={12}>
                    <Item label="Line 2" >{address.line2}</Item>            
                </Grid>
                <Grid key="address-postal-code" item xs={12}>
                    <Item label="Postal Code" >{address.postal_code}</Item>            
                </Grid>
                <Grid key="address-state" item xs={12}>
                    <Item label="State" >{address.state}</Item>            
                </Grid> 
            </>}
        </Grid>
    );
} 

export default FormAddress;

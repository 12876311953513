import { ReactNode } from 'react';
import { Form } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core';

interface Prop {
    onClick: ({ key, secret }: { key: string, secret: string }) => void
    children?: ReactNode,
}


const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "50%",
    },
    page: {
        display: "flex",
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
    },
    field: {
        textAlign: 'center',
        width: "100%",
        color: theme.palette.text.secondary,
    },
    item: {
        marginTop: theme.spacing(2),
        width: "100%",
    },
    itemHalf: {
        marginTop: theme.spacing(2),
        width: "33%",
    },
  }));

function FormKeySecret({ onClick, children }: Prop) {
    const style = useStyles()

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        const keyField = form.elements.key;
        const secretField = form.elements.secret;
        onClick({ key: keyField.value, secret: secretField.value })
        event.preventDefault();
    }
    return (
        <div className={style.page}>
            <Form className={style.form} onSubmit={handleSubmit}>
                <TextField className={style.item} id="key"  label="Key control" variant="outlined" type="text" color='primary' defaultValue="" />
                <div className={style.item} ></div>
                <TextField className={style.item} id="secret"  label="Secret" variant="outlined" type="password" color='primary' defaultValue="" />
                <div className={style.item} ></div>
                <Button className={style.itemHalf} variant='outlined' type="submit">Submit</Button>
                {children}
            </Form>
        </div>
    );
}

export default FormKeySecret;

import React, { ReactNode, useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import "./index.scss";
import { VoicemePage } from "./components/pages/voiceme-page";
import { Provider } from "react-redux";
import { store } from "./app/store";
import ReactDOM from "react-dom";
import { useAppDispatch } from "./app/hooks";
import { INIT } from "./app/api/actions";
import ConfigFlow from "./components/pages/ConfigFlow";
import PartnerSignupFlow from "./components/pages/PartnerSignupFlow";
import ProfilePage from "./components/pages/ProfilePage";
import KeySecretFlow from "./components/pages/KeySecretFlow";
import ErrorPage from "./components/pages/voiceme-error-page";
import VoiceOTPLoginFlow from "./components/pages/VoiceOTPLoginFlow";
import DocumentSignFlow from "./components/pages/DocumentSignFlow";
import { VoicemeOIDCLayout } from "./components/pages/voiceme-base-page";
import OidcWeb from "./components/pages/OidcWeb";
import Loader from "./components/organism/Loader";
import OidcOtp from "./components/pages/OidcOtp";
import DemoOIDC from "./components/pages/DemoOIDC";
import VoiceLogins from "./components/pages/VoiceLogins";
import VoiceSignup from "./components/pages/VoiceSignup";
import Oidc from "./components/pages/Oidc";



const router = createBrowserRouter([
  {
    path: "/",
    element: <VoicemePage><VoiceLogins></VoiceLogins></VoicemePage>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/register",
    element: <VoicemePage><VoiceSignup></VoiceSignup></VoicemePage>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/loader",
    element: <VoicemePage><Loader></Loader></VoicemePage>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/secretLogin",
    element: <VoicemePage><KeySecretFlow></KeySecretFlow></VoicemePage>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/config",
    element: <VoicemePage><ConfigFlow ></ConfigFlow></VoicemePage>,
    errorElement: <ErrorPage />
  },
  {
    path: "/signup-partner",
    element: <VoicemePage><PartnerSignupFlow ></PartnerSignupFlow></VoicemePage>,
    errorElement: <ErrorPage />
  },
  {
    path: "/profile",
    element: <VoicemePage><ProfilePage></ProfilePage></VoicemePage>,
    errorElement: <ErrorPage />
  },
  {
    path: "/personal",
    element: <VoicemePage><ProfilePage></ProfilePage></VoicemePage>,
    errorElement: <ErrorPage />
  },
  {
    path: "/voiceotp",
    element: <VoicemePage><VoiceOTPLoginFlow></VoiceOTPLoginFlow></VoicemePage>,
    errorElement: <ErrorPage />
  },
  {
    path: "/oidcdemo",
    element: <VoicemePage><DemoOIDC></DemoOIDC></VoicemePage>,
    errorElement: <ErrorPage />
  },
  {
    path: "/voicesign",
    element: <VoicemePage><DocumentSignFlow></DocumentSignFlow></VoicemePage>,
    errorElement: <ErrorPage />
  },
  {
    path: "/oidc/voiceotp",
    element: <VoicemeOIDCLayout><OidcOtp></OidcOtp></VoicemeOIDCLayout>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/oidc/voiceweb",
    element: <VoicemeOIDCLayout><OidcWeb></OidcWeb></VoicemeOIDCLayout>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/oidc",
    element: <VoicemeOIDCLayout><Oidc></Oidc></VoicemeOIDCLayout>,
    errorElement: <ErrorPage />,
  },
]);

export function InitVoiceme({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(INIT())
  }, [dispatch])
  return (<> {children} </>)
}

ReactDOM.render(
  <Provider store={store} >
    <React.StrictMode>
      <InitVoiceme><RouterProvider router={router} /></InitVoiceme>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

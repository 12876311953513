import { createSlice } from "@reduxjs/toolkit";
import { SidebarStore } from "./store";
import { sidebarCustomerClear, sidebarCustomerSet, sidebarPartnerClear, sidebarPartnerSet, sidebarVoicemeClear, sidebarVoicemeSet } from "./actions";
import { SidebarRole } from "./types";

const initialState: SidebarStore = {
    role: []
};

const apiStore = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(sidebarCustomerSet, (state, action) => {
                state.customer = action.payload;
                state.role = (["customer", ...state.role] as SidebarRole[]).filter((value, index, array) => array.indexOf(value) === index)
            })
            .addCase(sidebarPartnerSet, (state, action) => {
                state.partner = action.payload;
                state.role = (["partner", ...state.role] as SidebarRole[]).filter((value, index, array) => array.indexOf(value) === index)
            })
            .addCase(sidebarVoicemeSet, (state, action) => {
                state.partner = action.payload;
                state.role = (["voiceme", ...state.role] as SidebarRole[]).filter((value, index, array) => array.indexOf(value) === index)
            })
            .addCase(sidebarCustomerClear, (state) => {
                state.customer = undefined;
                state.role = state.role.filter(value => value !== "customer")
            })
            .addCase(sidebarPartnerClear, (state) => {
                state.partner = undefined;
                state.role = state.role.filter(value => value !== "partner")
            })
            .addCase(sidebarVoicemeClear, (state) => {
                state.partner = undefined;
                state.role = state.role.filter(value => value !== "voiceme")
            })
    }
});

export default apiStore.reducer;



import { Action } from "redux";
import { Observable, Subject, merge, of } from "rxjs";
import { delay, filter,  map,  switchMap, takeUntil, tap, withLatestFrom } from "rxjs/operators";
import { RootState } from "../store";
import { authorizeGetTrigger, notificationGetFailed, notificationGetProcessed, notificationPostTrigger } from "../api/actions";
import { authOtpComplete, authOtpErrorAuth, authOtpSetup } from "./actions";
 

export const epicAuthOtpSetup = (action$: Observable<Action>, state$: Observable<RootState>) => {
  const waitOtpSetupInit$ = action$.pipe(
    filter(authOtpSetup.match),
    withLatestFrom(state$),
    filter(([action, state]) => state.api.api == undefined),
    delay(200),
    map(([action, s]) => action)
  );  
  const otpSetupTrigger$ = action$.pipe(
    filter(authOtpSetup.match),
    withLatestFrom(state$),
    filter(([action, state]) => state.api.api != undefined),
    switchMap(([action, state]) => {
          const end = new Subject()
          return merge(
            of(notificationPostTrigger({service_id: "VOICEME", ...action.payload})),
            action$.pipe(
                filter(notificationGetProcessed.match),
                filter(a => a.payload.status === 'SUCCESS'),
                takeUntil(end),
                map((a) => {
                  end.complete()
                  return authOtpComplete(a.payload.token!)
                }),
            ),
            action$.pipe(
                filter(notificationGetFailed.match),
                takeUntil(end),
                map((a) => {
                  end.complete()
                  return authOtpErrorAuth(a.payload)
                }),
            ),
        )
    })
  );
  return merge(waitOtpSetupInit$,otpSetupTrigger$)
}

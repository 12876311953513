import { useDispatch, useSelector } from 'react-redux';
import { selectorEmailMobile, selectorSignup } from '../../../app-view/signup-view';
import Loader from '../../organism/Loader';
import { selectorEmail, selectorEnroll, selectorEnrollSession } from '../../../app-view/enroll-view';
import VoicemeRecorder from '../../organism/recorder/VoicemeRecorder';
import EnrollSentence from './EnrollSentence';
import { AuthorizePayload, EnrollPayload } from '../../../app/api/types';
import { enrollPostTrigger } from '../../../app/api/actions';
import EnrollComplete from './Enroll';

interface Prop {
    okComplete?: () => void
    okOnError?: () => void
    
}

function Enroll({okComplete, okOnError }: Prop) {
    const flow = useSelector(selectorEnroll)
    const enroll = useSelector(selectorEmail)
    const enrollSession = useSelector(selectorEnrollSession)
    const dispatch = useDispatch()

    const handleRecording = (payload: AuthorizePayload) => {
        const requestEnroll = {
            ...payload,
            email: enroll.email!,
            session_id: enrollSession.session_id!,
            pin_number: "123456789012"
        }
        dispatch(enrollPostTrigger(requestEnroll))
    }
    switch(flow.status) {
        case 'sentence':
            return (<EnrollSentence></EnrollSentence>);
        case 'enroll':
            return <VoicemeRecorder
                sentence={enrollSession.sentence}
                attempt={enrollSession.max_attempts}
                duration={enrollSession.media_length}
                action={handleRecording}
                cancel={() => console.log('cancel')}
                ></VoicemeRecorder>
        case 'success':
            return <EnrollComplete></EnrollComplete>
    }
    return <Loader></Loader>
}

export default Enroll;

import { useAppDispatch } from '../../app/hooks';
import Loader from '../organism/Loader';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectPartnerGetAPI } from '../../app-view/api-view'; 
import { logout, partnerGetTrigger, profilePaymentTrigger } from '../../app/api/actions';
import FormAddress from '../organism/FormAddress';
import SendIcon from '@mui/icons-material/Send';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Button, makeStyles } from '@material-ui/core';
import Message from '../organism/Message';


const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    }, 
}));


function ProfilePage() {
    const navigate = useNavigate()
    const partnerGetAPI = useSelector(selectPartnerGetAPI)
    const dispatch = useAppDispatch()
    const style = useStyles()
    const handlePortal = () => {
        dispatch(profilePaymentTrigger({return_url: window.location.origin + "/personal"}))
    }
    const handleLogout = () => {
        dispatch(logout())
    }
    const handleUpdateProfile = () => {
        dispatch(partnerGetTrigger())
    }
    if(partnerGetAPI?.response === undefined) {
        return (<Loader onClick={()=>navigate('/')} ></Loader>)
    } else if(partnerGetAPI?.error) {
        return (<Message  title={partnerGetAPI.error.code} onClick={handleLogout} >{partnerGetAPI.error.detail}</Message>)
    } else if(partnerGetAPI.response?.results?.[0].billing_status) {
        return (<>
            <FormAddress address={partnerGetAPI.response?.results?.[0].billing_status.address} hasPayment={partnerGetAPI.response?.results?.[0].billing_status.has_payment}></FormAddress>
            <Box padding={2}>
                <Button className={style.button} variant="contained" endIcon={<SendIcon />}  onClick={handlePortal}>Setup your billing info</Button>
                <Button className={style.button} variant="contained" endIcon={<LogoutIcon />}  onClick={handleLogout}>Logout</Button>
                <Button className={style.button} variant="contained" endIcon={<SendIcon />}  onClick={handleUpdateProfile}>Update Profile</Button>
            </Box>
        </>)
    }
    return (<Message  title="Errore sconosciuto" onClick={handleUpdateProfile} ></Message>)
}
export default ProfilePage;

import { MouseEventHandler } from 'react';
import { Children } from '../../type.d';
import { Button } from '@mui/material';

interface Props extends Children {
    onClick: MouseEventHandler<HTMLButtonElement>
    disabled?: boolean
    type?: "button" | "submit" | "reset" | undefined
    invert?: boolean
}

function VoicemeButton({ children, disabled=false, onClick, type = 'button', invert = false }: Props) {
    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            type={type} variant="outlined"
        >
            {children}
        </Button>
    )
}

export default VoicemeButton;

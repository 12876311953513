import { ConnectedProps, connect } from "react-redux"
import { RootState } from "../app/store"
import { createSelector } from 'reselect'
import { callApiStart, configEndpoint, configFailed, configFlowSetup } from "../app/api/actions"
import { AnyAction } from "redux"
import { Dispatch } from "react"

const ConfigProps = (state: RootState) => {
    const inProgress = state.api?.flowAPI?.status === 'progress'
    const inStart = state.api?.flowAPI?.status === 'start'
    const inIdle = state.api?.flowAPI?.status === 'idle'
    return {
        inProgress,
        inStart,
        inIdle,
        ...state.api,
    }
}

export const flowState = (state: RootState) => state.api?.flowAPI?.status
export const selectFlowStateBool = createSelector(flowState, f => ({inProgress: f === 'progress', inIdle: f === 'idle', inStart: f === 'start'}))
export const selectEndpoint = (state: RootState) => state.api?.api?.endpoint


export const performConfig = (dispatch: Dispatch<AnyAction>) => ({config}:{config: string}) => {

    dispatch(
        callApiStart({
            api: `https://api.voiceme-production.demo.infra.voiceme.id`,
            path: `/voiceme/configuration/?search=${config}`,
            method: "GET",
            success: configEndpoint,
            reject: configFailed,
            auth: false,
        }))
    dispatch(configFlowSetup({ state: 'progress' }))
}
export const handleReconfigure = (dispatch: Dispatch<AnyAction>) => () => {
    dispatch(configFlowSetup({ state: 'start' }))
}
export const cancel = (dispatch: Dispatch<AnyAction>) => () => {
    dispatch(configFlowSetup( { state: 'idle' }))
}

const mapDispatch = {
    toggleOn: () => ({ type: 'TOGGLE_IS_ON' }),
}




import { ReactNode } from "react";
import VoicemeHeader from "../molecule/VoicemeHeader";
import VoicemeRouter from "../router/voiceme-router";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

export function VoicemeOIDCLayout({ children }: { children?: ReactNode }) {
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <div className="App" style={{ marginTop: "80px" }}>
                    <VoicemeRouter>
                        <CssBaseline />
                        {children}
                    </VoicemeRouter>
                </div>
            </Box>
        </Box>
    )
}

import { createSlice } from '@reduxjs/toolkit';
import { apiToken } from '../api/actions';
import { OidcWebStore } from './types';
import { oidcWebReset, oidcWebStart } from './actions';

const initialState: OidcWebStore = {
    status: "request-web-auth"
};

export const oidcWeb = createSlice({
  name: 'oidc-web',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(oidcWebStart, (state, action) => {
      state.status = "request-web-auth"
      state.redirect = action.payload
    })
    .addCase(oidcWebReset, (state, action) => {
      state.status = "request-web-auth"
      state.redirect = undefined
    })
  },
});

export default oidcWeb.reducer;



import { Action } from "redux";
import { Observable } from "rxjs";
import { filter,  mergeMap, withLatestFrom } from "rxjs/operators";
import { RootState } from "../store";
import { INIT, partnerGetTrigger } from "../api/actions";

export const epicProfileStartup = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(a => INIT.match(a)),
        withLatestFrom(state$),
        filter(([, state]) => !!state.api.api),
        mergeMap(([action, state]) => [partnerGetTrigger()])
    );

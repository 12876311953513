import { ReactNode, useState } from 'react';
import { Form } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { SignupPayload } from '../../app/api/types';

interface Prop {
    onClick?: (payload: SignupPayload) => void; // Modifica la funzione per accettare SignupPayload
    children?: ReactNode;
    hideSubmit?: boolean;
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "100%",
    },
    page: {
        display: "flex",
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
    },
    field: {
        textAlign: 'center',
        width: "100%",
        color: theme.palette.text.secondary,
    },
    item: {
        marginTop: theme.spacing(2),
        width: "100%",
    },
    itemHalf: {
        marginTop: theme.spacing(2),
        width: "33%",
    },
}));

function FormSignup({ onClick, children, hideSubmit }: Prop) {
    const [validatedEmail, setValidatedEmail] = useState<string | undefined>(undefined);
    
    // Stati aggiuntivi per i nuovi campi
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [mobile, setMobile] = useState<string>('');
    const [customer, setCustomer] = useState<string>('');

    const handleSubmit = (event: any) => {
        event.preventDefault();
        
        const payload: SignupPayload = {
            first_name: firstName,
            last_name: lastName,
            email: email || undefined,
            mobile: mobile || undefined,
            customer: customer || undefined,
        };
        // Passa i dati al callback onClick
        onClick?.(payload);
    };

    const handleValidation = (event: any) => {
        const emailField = event.currentTarget;
        // Email validation
        const emailRegex = /^\S+@\S+\.\S+$/;
        if (emailRegex.test(emailField.value)) {
            setValidatedEmail(undefined);
        } else {
            setValidatedEmail("Please enter a valid email address.");
        }
    };

    const style = useStyles();

    return (
        <div className={style.page}>
            <Form className={style.form} onSubmit={handleSubmit}>
                {/* Campo First Name */}
                <TextField
                    className={style.item}
                    label="First Name"
                    variant="outlined"
                    type="text"
                    color="primary"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                
                {/* Campo Last Name */}
                <TextField
                    className={style.item}
                    label="Last Name"
                    variant="outlined"
                    type="text"
                    color="primary"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />

                {/* Campo Email */}
                <TextField
                    className={style.item}
                    id="email"
                    error={validatedEmail !== undefined}
                    onChange={(e) => {
                        setEmail(e.target.value); // Aggiorna lo stato email
                        handleValidation(e); // Valida l'email
                    }}
                    helperText={validatedEmail}
                    label="Please enter your Email"
                    variant="outlined"
                    type="email"
                    color="primary"
                    value={email}
                />

                {/* Campo Mobile */}
                <TextField
                    className={style.item}
                    label="Mobile"
                    variant="outlined"
                    type="tel"
                    color="primary"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                />

                {/* Campo Customer opzionale */}
                <TextField
                    className={style.item}
                    label="Customer (optional)"
                    variant="outlined"
                    type="text"
                    color="primary"
                    value={customer}
                    onChange={(e) => setCustomer(e.target.value)}
                />

                <div className={style.item}></div>
                
                {/* Submit Button */}
                {!hideSubmit && (
                    <Button className={style.itemHalf} variant="outlined" type="submit">
                        Submit
                    </Button>
                )}

                {children}
            </Form>
        </div>
    );
}

export default FormSignup;

import { useAppDispatch } from '../../app/hooks';
import { ReactNode, useEffect } from 'react';
import { documentGetTrigger, m2mAuthorizeClear, m2mAuthorizeTrigger, notificationPostTrigger } from '../../app/api/actions';
import { useSelector } from 'react-redux';
import { selectNotificationPost } from '../../app-view/api-view';
import Message from '../organism/Message';
import FormEmail from '../organism/FormEmail';
import { NotificationResponse } from '../../app/api/types';
import { selectDocument, selectDocuments } from '../../app-view/document-view';
import DocumentSign from '../organism/DocumentSign';


interface Prop {
    children?: ReactNode,
}
function DocumentSignFlow({children} : Prop) {
    const dispatch = useAppDispatch()
    const items = useSelector(selectDocuments)
    const document = useSelector(selectDocument)
    useEffect(() => {
        dispatch(documentGetTrigger())
    }, [dispatch])
    if(items) {
        return <DocumentSign documents={items} document={document}/>
    }
    return <></>
}
export default DocumentSignFlow;

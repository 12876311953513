import { useAppDispatch } from '../../app/hooks';
import Loader from '../organism/Loader';
import { selectEndpoint } from '../../app-view/config-view';
import { useSelector } from 'react-redux';
import { ReactNode, useEffect } from 'react';
import { signupPartnerDataFill, signupPartnerFlowSet, signupPartnerOTPFill,   signupPartnerResetFill } from '../../app/partner-signup/actions';
import {  selectSignupPartnerData, selectSignupPartnerFlow  } from '../../app-view/partner-signup-view';
import FormPartner from '../organism/FormPartner';
import { PARTNER_SIGNUP_COMPLETE, PARTNER_SIGNUP_ERROR, PARTNER_SIGNUP_FILL_DATA, PARTNER_SIGNUP_LOADING, PARTNER_SIGNUP_OTP, PARTNER_SIGNUP_START } from '../../app/partner-signup/types';
import { useNavigate } from 'react-router';
import { selectLastAPI } from '../../app-view/api-view';
import { SignupPartnerPostPayload } from '../../app/api/types';
import Message from '../organism/Message';
import FormOTP from '../organism/FormOTP';

interface Prop {
    children?: ReactNode,
}

function PartnerSignupFlow({children} : Prop) {
    const dispatch = useAppDispatch()
    const flow = useSelector(selectSignupPartnerFlow)
    const data = useSelector(selectSignupPartnerData)
    const lastApi = useSelector(selectLastAPI)
    const navigate = useNavigate()
    const endpoint = useSelector(selectEndpoint)

    useEffect(() => {
        dispatch(signupPartnerResetFill())
        dispatch(signupPartnerFlowSet(PARTNER_SIGNUP_START))
    }, [dispatch])

    const handleRequestOTP = (request: SignupPartnerPostPayload) => {
        dispatch(signupPartnerOTPFill(request))
    }
    const handleRequestData = (request: SignupPartnerPostPayload) => {
        dispatch(signupPartnerDataFill(request))
    }
    const handleCancel = () => {
        dispatch(signupPartnerFlowSet(PARTNER_SIGNUP_START))
    }
    const handleComplete = () => {
        dispatch(signupPartnerFlowSet(PARTNER_SIGNUP_START))
        navigate('/profile')
    }
    switch(flow) {
    case PARTNER_SIGNUP_OTP:
        return <FormOTP data={data} onClick={handleRequestOTP}>{children}</FormOTP>
    case PARTNER_SIGNUP_LOADING:
        return <Loader onClick={()=>navigate('/services')} ></Loader>
    case PARTNER_SIGNUP_ERROR:
        let onError = ()=>dispatch(signupPartnerFlowSet(PARTNER_SIGNUP_FILL_DATA))
        return <Message  title={lastApi.error?.code} onClick={onError} >{lastApi.error?.detail}</Message>
    case PARTNER_SIGNUP_COMPLETE:
        return <Message  title='Congratulation' onClick={handleComplete} >Account created. Please go to the payment setup.</Message>
    case PARTNER_SIGNUP_START:
    case PARTNER_SIGNUP_FILL_DATA:
    default:
        return <FormPartner data={data} onCancel={handleCancel} onClick={handleRequestData}>{children}</FormPartner>
    }
}
export default PartnerSignupFlow;



import { Action } from "redux";
import { Observable, merge, of } from "rxjs";
import { catchError, filter,  ignoreElements,  map,  switchMap, take, tap, timeout, withLatestFrom } from "rxjs/operators";
import { RootState } from "../store";
import { notificationGetProcessed,  notificationPostProcessed, notificationPostTrigger, oidcRedirectPostProcessed, oidcRedirectPostTrigger, oidcRedirectProcessed, oidcRedirectTrigger } from "../api/actions";
import { oidcError, oidcSuccess, oidcVoiceOtpNotify  } from "./actions";
import { NotificationResponse } from "../api/types";
import { PayloadAction } from "@reduxjs/toolkit";

export const _raiseError = (error: any) => {
    if (error.name === 'TimeoutError') {
        return of(oidcError('timeout'));
    }
    return of(oidcError('unknown'));
}

export const _epicCatchPostRedirect_ShipSuccess = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(oidcRedirectPostProcessed.match),
        timeout(5000),
        take(1),
        withLatestFrom(state$),
        map(() =>oidcSuccess()),
        catchError(() => of(oidcSuccess())),
    )

export const _epicCatchGetRedirect_ShipPostRedirect = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(oidcRedirectProcessed.match),
        timeout(5000),
        take(1),
        withLatestFrom(state$),
        switchMap(([, state]) =>
            merge(
                of(oidcRedirectPostTrigger(state?.oidcOtp?.oidcParms!)),
                _epicCatchPostRedirect_ShipSuccess(action$, state$),
            )
        ),
        catchError(_raiseError),
    )

export const _epicCatchRedirect_ShipPostRedirect = (action: PayloadAction<NotificationResponse>, action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(notificationGetProcessed.match),
        filter(action => action.payload.session_id == action.payload.session_id),
        filter(action => action.payload.token != undefined),
        timeout(50000),
        take(1),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            merge(
                of(oidcRedirectTrigger(state?.oidcOtp?.oidcParms!)),
                _epicCatchGetRedirect_ShipPostRedirect(action$, state$),
            )
        ),
        catchError(_raiseError),
    )

export const _epicCatchNotificationPost_ShipNotificationGet = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(notificationPostProcessed.match),
        timeout(5000),
        take(1),
        switchMap((action) =>
            merge(
                _epicCatchRedirect_ShipPostRedirect(action, action$, state$),
            )
        ),
        catchError(_raiseError),
    )


export const epicOidcVoiceOtp = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(oidcVoiceOtpNotify.match),
        withLatestFrom(state$),
        filter(([, state]) => !!state.api.api),
        switchMap(([action,]) =>
            merge(
                of(notificationPostTrigger(action.payload)),
                _epicCatchNotificationPost_ShipNotificationGet(action$, state$),
            )
        )
    );

import { createSlice } from '@reduxjs/toolkit';
import { authOtpComplete, authOtpErrorAuth, authOtpSetup, authOtpStartup } from './actions';
import { AuthOtpStore } from './types';

const initialState: AuthOtpStore = {
    status: "request-user"
};

export const authOtp = createSlice({
  name: 'auth-otp',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(authOtpStartup, (state, action) => {
      state.authGetResponse = undefined
      state.email = undefined
      state.error = undefined
      state.status = "request-user"
    })
    .addCase(authOtpSetup, (state, action) => {
      state.authGetResponse = undefined
      state.email = action.payload.email
      state.error = undefined
      state.status = "loading-phrase"
    })
    .addCase(authOtpComplete, (state, action) => {
      state.status = "complete"
    })
    .addCase(authOtpErrorAuth, (state, action) => {
      state.status = "auth-error-auth"
      state.error = action.payload
    })
  },
});

export default authOtp.reducer;

import { ReactNode, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { ConfirmPayload } from '../../app/api/types'; // Import the existing ConfirmPayload type

interface Prop {
    email?: string;              // Predefined email value from another page
    mobile?: string;             // Predefined mobile value from another page
    readOnlyEmail?: boolean;    // Make email read-only
    readOnlyMobile?: boolean;   // Make mobile read-only
    onConfirm?: (payload: ConfirmPayload) => void;
    children?: ReactNode;
    hideSubmit?: boolean;
    hideEmail?: boolean;        // Hide both email and OTP email fields
    hideMobile?: boolean;       // Hide both mobile and OTP mobile fields
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "100%",
    },
    page: {
        display: "flex",
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
    },
    field: {
        textAlign: 'center',
        width: "100%",
        color: theme.palette.text.secondary,
    },
    item: {
        marginTop: theme.spacing(2),
        width: "100%",
    },
    itemHalf: {
        marginTop: theme.spacing(2),
        width: "33%",
    },
    errorMessage: {
        color: "red",
        textAlign: "center",
        width: "100%",
    }
}));

function FormConfirm({ 
    email, 
    mobile, 
    readOnlyEmail, 
    readOnlyMobile, 
    onConfirm, 
    children, 
    hideSubmit, 
    hideEmail, 
    hideMobile 
}: Prop) {
    // States to manage OTP inputs and errors
    const [otpEmail, setOtpEmail] = useState<string>('');
    const [otpMobile, setOtpMobile] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    if(hideEmail === undefined)
        hideEmail = email === undefined
    if(hideMobile === undefined)
        hideMobile = mobile === undefined
    const handleSubmit = (event: any) => {
        event.preventDefault();

        // Validate that at least one OTP is provided
        if ((!hideEmail && !otpEmail) && (!hideMobile && !otpMobile)) {
            setError("Please enter at least one OTP (either Email or Mobile).");
            return;
        }

        // Prepare the payload using the imported ConfirmPayload type
        const payload: ConfirmPayload = {
            email: email || undefined,        // Pre-filled email from the attribute
            otp_email: otpEmail || undefined, // OTP for email
            mobile: mobile || undefined,      // Pre-filled mobile from the attribute
            otp_mobile: otpMobile || undefined // OTP for mobile
        };
        console.log(payload)
        // Clear error on successful submission
        setError(null);

        // Call the onConfirm callback with the payload
        onConfirm?.(payload);
    };

    const handleOtpValidation = (value: string) => {
        // OTP must be exactly 6 digits
        const otpRegex = /^\d{6}$/;
        return otpRegex.test(value);
    };

    const style = useStyles();

    return (
        <div className={style.page}>
            <form className={style.form} onSubmit={handleSubmit}>
                
                {/* Email Input and OTP Email Input (show only if hideEmail is false) */}
                {!hideEmail && (
                    <>
                        <TextField
                            className={style.item}
                            label="Email"
                            variant="outlined"
                            type="email"
                            color="primary"
                            value={email}                     // Pre-fill from props
                            InputProps={{
                                readOnly: readOnlyEmail || false, // Set read-only if prop is true
                            }}
                            helperText="Your email address"
                        />

                        <TextField
                            className={style.item}
                            label="OTP Email"
                            variant="outlined"
                            type="text"
                            color="primary"
                            value={otpEmail}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (handleOtpValidation(value)) {
                                    setOtpEmail(value);
                                    setError(null); // Clear any previous error
                                } else {
                                    setError("OTP Email must be exactly 6 digits.");
                                }
                            }}
                            helperText="Enter the 6-digit OTP for your email"
                        />
                    </>
                )}

                {/* Mobile Input and OTP Mobile Input (show only if hideMobile is false) */}
                {!hideMobile && (
                    <>
                        <TextField
                            className={style.item}
                            label="Mobile"
                            variant="outlined"
                            type="tel"
                            color="primary"
                            value={mobile}                    // Pre-fill from props
                            InputProps={{
                                readOnly: readOnlyMobile || false, // Set read-only if prop is true
                            }}
                            helperText="Your mobile number"
                        />

                        <TextField
                            className={style.item}
                            label="OTP Mobile"
                            variant="outlined"
                            type="text"
                            color="primary"
                            value={otpMobile}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (handleOtpValidation(value)) {
                                    setOtpMobile(value);
                                    setError(null); // Clear any previous error
                                } else {
                                    setError("OTP Mobile must be exactly 6 digits.");
                                }
                            }}
                            helperText="Enter the 6-digit OTP for your mobile"
                        />
                    </>
                )}

                {/* Error Message */}
                {error && <div className={style.errorMessage}>{error}</div>}

                <div className={style.item}></div>

                {/* Submit Button */}
                {!hideSubmit && (
                    <Button className={style.itemHalf} variant="outlined" type="submit">
                        Confirm
                    </Button>
                )}

                {children}
            </form>
        </div>
    );
}

export default FormConfirm;

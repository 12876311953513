import { useAppDispatch } from '../../app/hooks';
import { ReactNode } from 'react';
import FormKeySecret from '../organism/FormKeySecret';
import { m2mAuthorizeClear, m2mAuthorizeTrigger } from '../../app/api/actions';
import { useSelector } from 'react-redux';
import { selectM2mAuthorizeAPI } from '../../app-view/api-view';
import Message from '../organism/Message';
import { selectorTokenPresent } from '../../app/api/selector';


interface Prop {
    children?: ReactNode,
}
function KeySecretFlow({children} : Prop) {
    const dispatch = useAppDispatch()
    const m2mAuth = useSelector(selectM2mAuthorizeAPI)
    const isTokenPresent = useSelector(selectorTokenPresent)
    const handleSubmit = (request: {key :string, secret: string }) => {
        dispatch(m2mAuthorizeTrigger(request))
    }
    const handleClear = () => {
        if(isTokenPresent) {
            dispatch(m2mAuthorizeClear())
        }
        dispatch(m2mAuthorizeClear())
    }
    if(m2mAuth?.error) {
        return <Message title={`Error during login ${m2mAuth.error.code}`} onClick={handleClear} >{m2mAuth.error.detail}</Message>
    } else if(isTokenPresent) {
        return <Message severity='success' title='Congratulation' onClick={handleClear} >Login success.</Message>
    }
    return (<FormKeySecret onClick={handleSubmit}  >{children}</FormKeySecret>)

}
export default KeySecretFlow;

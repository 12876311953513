import { ReactNode } from 'react';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { useAppDispatch } from '../../../app/hooks';
import { enroll } from '../../../app/enroll/reducer';
import { enrollGetTrigger } from '../../../app/api/actions';
import { AuthGetRequest } from '../../../app/api/types';
import { useSelector } from 'react-redux';
import { selectorEmail } from '../../../app-view/enroll-view';

interface Prop {
    onStartBiometricEnrollment?: () => void;  // Callback for biometric profile action
    children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    question: {
        marginBottom: theme.spacing(2),
        color: theme.palette.text.primary,
        textAlign: 'center',
    },
    button: {
        marginTop: theme.spacing(2),
        width: '100%',
        maxWidth: '300px',
    },
}));

function EnrollSentence({ onStartBiometricEnrollment, children }: Prop) {
    const style = useStyles();
    const dispatch = useAppDispatch()
    const emailSelector = useSelector(selectorEmail)

    const handleStartBiometricEnrollment = () => {
        const payload: AuthGetRequest = {
            email: emailSelector.email!,
            session_id: undefined,
        }
        dispatch(enrollGetTrigger(payload))
        onStartBiometricEnrollment?.();
    };

    return (
        <div className={style.container}>
            <Typography variant="h5" className={style.question}>
                Would you like to enroll a new biometric profile?
            </Typography>
            <Button
                className={style.button}
                variant="contained"
                color="primary"
                onClick={handleStartBiometricEnrollment}
            >
                Start New Biometric Profile
            </Button>
            {children}
        </div>
    );
}

export default EnrollSentence;

import { MouseEventHandler } from 'react';
import { useNavigate } from 'react-router';
import { Children } from '../../type.d';
import { Button, Paper, Typography, makeStyles } from '@material-ui/core';
import { Alert, AlertColor } from '@mui/material';

interface Props extends Children {
    onClick?: MouseEventHandler<HTMLButtonElement>
    severity?: AlertColor,
    title?: string
}

const useStyles = makeStyles((theme) => ({
    page: {
        display: "flex",
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
    },
    form: {
        display: "flex",
        flexWrap: "wrap",
        width: "50%",
    },
    field: {
        textAlign: 'center',
        width: "100%",
        color: theme.palette.text.secondary,
    },
    item: {
        marginTop: theme.spacing(2),
        width: "100%",
    },
  }));

function Message({
        onClick,
        severity = "error", 
        title = "Error",
        children,
    }: Props) {
    const navigate = useNavigate()
    const onClickCalc = onClick || (() => {navigate('/')})
    const style = useStyles()
    return (
        <div className={style.page}>
            <Paper className={style.form} elevation={24}>
                <Alert className={style.field} severity={severity}>
                    <Typography variant='h5'>{title}</Typography>
                    <Typography variant='caption'>{children}</Typography>
                </Alert>
                <Button className={style.item} variant='outlined' type="button" onClick={onClickCalc}>Close</Button>
            </Paper>
        </div>
    );
}

export default Message;

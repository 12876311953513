import { useSelector } from 'react-redux';
import { selectorEmailMobile, selectorSignup } from '../../../app-view/signup-view';
import Register from './Register';
import Confirm from './Confirm';
import Loader from '../../organism/Loader';

interface Prop {
    okComplete?: () => void
    okOnError?: () => void
    
}

function Signup({okComplete, okOnError }: Prop) {
    const flow = useSelector(selectorSignup)
    const emailMobile = useSelector(selectorEmailMobile)
    switch(flow.status) {
        case 'signup':
            return (<Register></Register>);
        case 'confirm':
            return (<Confirm email={emailMobile.email} mobile={emailMobile?.mobile}></Confirm>);
    }
    return <Loader></Loader>
}

export default Signup;

import { useAppDispatch } from '../../../app/hooks';
import { useSelector } from 'react-redux';
import { selectorAuthWebRequest } from '../../../app-view/auth-web';
import { ConfirmPayload } from '../../../app/api/types';
import { confirmPostTrigger } from '../../../app/api/actions';
import FormConfirm from '../../organism/FormConfirm';

interface Prop {
    submitted?: (payload: ConfirmPayload) => void
    email?: string
    mobile?: string
}

function Register({ submitted, email, mobile }: Prop) {
    const dispatch = useAppDispatch()
    const authWeb = useSelector(selectorAuthWebRequest)

    const handleConfirm = (payload: ConfirmPayload) => {
        dispatch(confirmPostTrigger(payload))
        submitted?.(payload)
    }
    return (
        <>
            <FormConfirm 
                email={email}
                mobile={mobile}
                onConfirm={handleConfirm}
                ></FormConfirm>
        </>
    );
}

export default Register;

import { createSlice } from "@reduxjs/toolkit";
import { DocumentVoiceSignStore } from "./store";
import { documentGetProcessed, documentUserDeleteProcessed, documentUserGetProcessed, documentUserPatchProcessed } from "../api/actions";
import { DocumentVoiceSign } from "./types";

const initialState: DocumentVoiceSignStore = {
};

const apiStore = createSlice({
    name: 'documens',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(documentGetProcessed, (state, action) => {
                state.documents = action.payload.results?.map(r => ({
                    document: r.document_base64,
                    documentSigned: r.document_signed_base64,
                    fileName: r.file_name,
                    id: r.id,
                } as unknown as DocumentVoiceSign));
            })
            .addCase(documentUserGetProcessed, (state, action) => {
                state.current = {
                    fileName: action.payload.file_name,
                    document: action.payload.document_base64,
                    documentSigned: action.payload.document_signed_base64,
                    id: action.payload.id,
                } as unknown as DocumentVoiceSign
            })
            .addCase(documentUserPatchProcessed, (state, action) => {
                state.current = {
                    fileName: action.payload.file_name,
                    document: action.payload.document_base64,
                    documentSigned: action.payload.document_signed_base64,
                    id: action.payload.id,
                } as unknown as DocumentVoiceSign
            })
            .addCase(documentUserDeleteProcessed, (state, action) => {
                state.current = undefined
            })
    }
});

export default apiStore.reducer;

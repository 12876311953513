import { ReactNode, useState } from 'react';
import { AddressType } from '../../app/api/types';
import { Grid, Typography } from '@mui/material';
import { Button, TextField, Paper, makeStyles } from '@material-ui/core';
import { selectLastAPI } from '../../app-view/api-view';
import { DocumentVoiceSign } from '../../app/document/types';
import { useDispatch, useSelector } from 'react-redux';
import { selectDocuments } from '../../app-view/document-view';
import { documentGetTrigger, documentPostTrigger, documentUserDeleteTrigger, documentUserGetTrigger, documentUserPatchTrigger } from '../../app/api/actions';
import { Form } from 'react-router-dom';

interface Prop {
    documents: DocumentVoiceSign[]
    document?: DocumentVoiceSign
}

const useStyles = makeStyles((theme) => ({
    // Define your styles here
    addressContainer: {
        // Add your styles for the container
        // Example:
        backgroundColor: 'lightgray',
        padding: theme.spacing(2),
    },
    fieldContainer: {
        // Add your styles for the container
        // Example:
        backgroundColor: 'lightgray',
        padding: theme.spacing(0),
    },
    label: {
        // Add your styles for Typography components
        // Example:
        border: "1px",
        backgroundColor: theme.palette.type === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
    },
    form: {
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "50%",
    },
    listDocument: {
        // Add your styles for Typography components
        // Example:
        border: "1px",
        backgroundColor: theme.palette.type === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
    },
    listDocumentGrid: {
        // Add your styles for Typography components
        // Example:
        border: "1px",
        ...theme.typography.body2,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%',
    },
    text: {
        // Add your styles for Typography components
        // Example:
        border: "1px",
        backgroundColor: theme.palette.type === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    payment: {
        textAlign: "center",
    },
    noPayment: {
        textAlign: "center",
        color: "red",
        style: "bold",
    }
}));

function Document({ fileName, id }: DocumentVoiceSign) {
    const style = useStyles()
    const dispatch = useDispatch()
    return (
        <Grid item xs={12} className={style.listDocumentGrid}>
            <Button className={style.listDocument} onClick={() => {
                dispatch(documentUserGetTrigger({id}))
            }}>{fileName}</Button>
        </Grid>
    )
}

function DocumentDetail(doc: DocumentVoiceSign) {
    const style = useStyles()
    const dispatch = useDispatch()
    return (
        <Grid container spacing={1} className={style.fieldContainer}>
            <Grid item xs={5}>
                <Typography className={style.label} >File Name</Typography> 
            </Grid>
            <Grid item xs={7}>
                <Typography className={style.label} >{doc.fileName}</Typography>
            </Grid>
            { doc.document && (
                <>
                    <Grid item xs={5}>
                        <Typography className={style.label} >Original Document</Typography> 
                    </Grid>
                    <Grid item xs={7}>
                        <a href={`data:application/octet-stream;base64,${doc.document}`} download={doc.fileName}>Download</a>
                    </Grid>
                </>
            )}
            { doc.documentSigned && (
                <>
                    <Grid item xs={5}>
                        <Typography className={style.label} >Signed Document</Typography> 
                    </Grid>
                    <Grid item xs={7}>
                        <a href={`data:application/octet-stream;base64,${doc.documentSigned}`} download={doc.fileName.replace('\.','-sign.')}>Download</a>
                    </Grid>
                </>
            )}
            { !doc.documentSigned && (
                <>
                    <Grid item xs={12}>
                        <Button className={style.listDocument} onClick={() => {
                            dispatch(documentUserPatchTrigger({id: doc.id, sign: true, service_id: "VOICESIGN"}))
                        }}>Sign the document</Button>
                    </Grid>
                </>
            )}
            {(
                <>
                    <Grid item xs={12}>
                        <Button className={style.listDocument} onClick={() => {
                            dispatch(documentUserDeleteTrigger(doc.id))
                        }}>Delete Document</Button>
                    </Grid>
                </>
            )}
        </Grid>
    )
}

function DocumentSign(prop: Prop) {
    const [selectedFile, setSelectedFile] = useState(null);
    const style = useStyles()
    const dispatch = useDispatch()

    const handleSubmit = (event:any) => {
        event.preventDefault();
        if (selectedFile) {
            const fileName = (selectedFile as any).name
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onload = () => {
                const base64Data = reader.result;
                // Here you can use base64Data
                dispatch(documentPostTrigger({
                    document: base64Data?.toString().split(',')[1],
                    fileName,
                }))
            };
            reader.onerror = (error) => {
                console.error('Error occurred while reading the file:', error);
            };
        } else {
            console.log("No file selected.");
        }
    }
    const handleFileChange = (event:any) => {
        setSelectedFile(event.target.files[0]);
    }
    return (
        <>
            <Grid container spacing={1} className={style.addressContainer}>
                <Grid key="item-action" item xs={12}>
                    <Form className={style.form} onSubmit={handleSubmit}>
                        <div className={style.label}>
                            <input type="file" onChange={handleFileChange} />
                        </div>
                        <Button className={style.label} variant='outlined' type="submit">Submit</Button>
                    </Form>
                </Grid>
            </Grid>
            <div></div>
            <Grid container spacing={1} className={style.addressContainer}>
                <Grid key="item-list" item xs={4}>
                    {prop.documents.map((item, i) => (<>
                        <Document {...item} ></Document>
                    </>))
                    }
                </Grid>
                <Grid key="item-detail" item xs={8}>
                        {prop.document && <DocumentDetail {...prop.document} ></DocumentDetail>}
                </Grid>
            </Grid>
        </>
    );
}

export default DocumentSign;

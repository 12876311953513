import { createSlice } from "@reduxjs/toolkit";
import { signupPartnerDataFill, signupPartnerResetFill, signupPartnerFlowSet, signupPartnerOTPFill } from "./actions";
import { PartnerSignupStore } from "./store";
import { PARTNER_SIGNUP_START } from "./types";

const initialState: PartnerSignupStore = {
  flow: PARTNER_SIGNUP_START,
  data: {
    partner: {
      name: ""
    }
  },
};

export const partnerSignup = createSlice({
  name: 'partnerSignup',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(signupPartnerDataFill, (state, action) => {
      state.data = action.payload
    })
    .addCase(signupPartnerOTPFill, (state, action) => {
      state.data = action.payload
    })
    .addCase(signupPartnerResetFill, (state) => {
      state.data = {
        partner: {
          name: ""
        }
      }
    })
    .addCase(signupPartnerFlowSet, (state, action) => {
      state.flow = action.payload
    })
  },
});

export default partnerSignup.reducer;

import { JSXElementConstructor, ReactElement, useEffect, useState } from 'react';

import { Tab, Tabs, Toolbar, makeStyles } from '@material-ui/core';
import AppBar from '@mui/material/AppBar';
import { Link } from 'react-router-dom';
import { SidebarMenuView } from '../../type.d';

interface Props {
  menu: SidebarMenuView,
  bg?: string
  mode?: string
  children?: ReactElement<{ elevation: number; }, string | JSXElementConstructor<any>>,
}

const useStyles = makeStyles((theme: any) => ({
  tabContainer: {
    marginLeft: "auto"
  },
  tab: {
    textTransform: "none",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "1rem"
  }
}));

function VoicemeHeader(props: Props) {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    const idx = props.menu.items.map(v => v.path).indexOf(
      window.location.pathname
    );
    if (tabIndex !== idx) {
      setTabIndex(idx);
    }
  }, [tabIndex]);

  const handleTabChange = (evt: unknown, index: number) => {
    setTabIndex(index);
  };

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} style={{color: 'white', backgroundColor: "#5358f7"}}>
      <Toolbar>
        <Toolbar>
          <Tabs
            className={classes.tabContainer}
            value={tabIndex}
            onChange={handleTabChange}
          >
            {props.menu.items.map((v, i) => (
              <Tab key={`header-d-${i}`} icon={v.icon} className={classes.tab} component={Link} to={v.path!} label={v.label!} />
            ))}
          </Tabs>
        </Toolbar>
      </Toolbar>
    </AppBar>
  );
}

export default VoicemeHeader;



import { Action } from "redux";
import { Observable } from "rxjs";
import { filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { apiToken, signupPartnerFailed, signupPartnerGetFailed, signupPartnerGetProcessed, signupPartnerGetTrigger, profilePaymentProcessed, profilePaymentTrigger, signupPartnerProcessed, signupPartnerTrigger } from "../api/actions";
import { RootState } from "../store";
import { PARTNER_SIGNUP_LOADING, PARTNER_SIGNUP_ERROR, PARTNER_SIGNUP_OTP } from "./types";
import { signupPartnerFlowSet, signupPartnerDataFill, signupPartnerOTPFill } from "./actions";

export const epicSignupPartnerFlowOTPtoDATA = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(signupPartnerGetProcessed.match),
        withLatestFrom(state$),
        filter(([, state]) => !!state.api.api),
        map(([action, state]) => signupPartnerFlowSet(PARTNER_SIGNUP_OTP))
    );
export const epicSignupPartnerFlowDATAtoREGISTER = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(signupPartnerProcessed.match),
        withLatestFrom(state$),
        filter(([, state]) => !!state.api.api),
        mergeMap(([action, state]) => [apiToken(action.payload!), profilePaymentTrigger({return_url: window.location.origin})])
    );

export const epicSignupPartnerFlowPayment = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(profilePaymentProcessed.match),
        withLatestFrom(state$),
        filter(([, state]) => !!state.api.api),
        mergeMap(([action, state]) => [window.location.replace(action.payload.url)])
    );

export const epicSignupPartnerDataFill = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(signupPartnerDataFill.match),
        withLatestFrom(state$),
        filter(([, state]) => !!state.api.api),
        mergeMap(([action, state]) => [signupPartnerGetTrigger({email: action.payload.partner!.email!}), signupPartnerFlowSet(PARTNER_SIGNUP_LOADING)])
    );

export const epicSignupPartnerOTPFill = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(signupPartnerOTPFill.match),
        withLatestFrom(state$),
        filter(([, state]) => !!state.api.api),
        mergeMap(([action, state]) => [signupPartnerTrigger(action.payload), signupPartnerFlowSet(PARTNER_SIGNUP_LOADING)])
    );

export const epicSignupPartnerFlowFail = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(a => signupPartnerGetFailed.match(a) || signupPartnerFailed.match(a)),
        withLatestFrom(state$),
        filter(([, state]) => !!state.api.api),
        mergeMap(([action, state]) => [signupPartnerFlowSet(PARTNER_SIGNUP_ERROR)])
    );

import { useAppDispatch } from '../../app/hooks';
import Config from '../organism/Config';
import { performConfig } from '../../app-view/config-view';
import { configureGetClear, configureGetTrigger } from '../../app/api/actions';
import { Dispatch, useEffect, useState } from 'react';
import { AnyAction } from 'redux';
import { useSelector } from 'react-redux';
import { selectAPI, selectConfigureGet } from '../../app-view/api-view';
import Message from '../organism/Message';
import { APIData, ConfigureGetPayload, ConfigureGetResponse, GenericError } from '../../app/api/types';

function ConfigFlow() {
    const dispatch = useAppDispatch()
    const configureGet = useSelector(selectConfigureGet)
    useEffect(() => {
        dispatch(configureGetClear())
    }, [dispatch])
    const performConfig = (config:{config: string}) => {
        dispatch(configureGetTrigger(config))
    }
    const clear = ()=>dispatch(configureGetClear())
    if(configureGet?.error)
        return (<Message  title={configureGet.error.code} onClick={clear} >{configureGet.error.detail}</Message>)
    if(configureGet?.response && configureGet.response.environments?.length == 0)
        return (<Message  title="Configurazione non trovata" onClick={clear} ></Message>)
    if(configureGet?.response)
        return (<Message  title="Configurazione completata" severity='success' onClick={clear} ></Message>)
    return <Config onClick={performConfig}/>
}
export default ConfigFlow;

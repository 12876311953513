
export const PARTNER_SIGNUP_START = 'start'
export const PARTNER_SIGNUP_SIGNUP = 'signup'
export const PARTNER_SIGNUP_LOADING = 'loading'
export const PARTNER_SIGNUP_FILL_DATA = 'fill-data'
export const PARTNER_SIGNUP_OTP = 'otp'
export const PARTNER_SIGNUP_ERROR = 'error'
export const PARTNER_SIGNUP_COMPLETE = 'complete'

export type PartnerSignupFlowType = typeof PARTNER_SIGNUP_OTP | typeof PARTNER_SIGNUP_FILL_DATA | typeof PARTNER_SIGNUP_START | typeof PARTNER_SIGNUP_SIGNUP | typeof PARTNER_SIGNUP_LOADING | typeof PARTNER_SIGNUP_ERROR | typeof PARTNER_SIGNUP_COMPLETE

import { createAction } from "@reduxjs/toolkit";
import { AuthGetRequest, AuthGetResponse, AuthorizePayload, GenericError, VoicemeToken } from "../api/types";

export const authWebStartup = createAction<void>("auth/web/startup");
export const authWebSetup = createAction<AuthGetRequest>("auth/web/setup");
export const authWebRecord = createAction<AuthGetResponse>("auth/web/record");
export const authWebRequest = createAction<AuthorizePayload>("auth/web/request");
export const authWebComplete = createAction<VoicemeToken>("auth/web/complete");
export const authWebErrorSentence = createAction<GenericError>("auth/web/error-sentence");
export const authWebErrorAuth = createAction<GenericError>("auth/web/error-auth");

import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { NotificationPayload, OIDCPayload } from '../../../app/api/types';
import { oidcVoiceOtpNotify, oidcVoiceOtpReset, oidcVoiceOtpStart } from '../../../app/oidc-otp/actions';
import FormEmailService from '../../organism/FormEmailService';
import { useNavigate } from 'react-router';
import { authWebSetup } from '../../../app/auth-web/actions';
import { Button, makeStyles } from '@material-ui/core';
import OidcOtp from '../OidcOtp';
import AuthWeb from '../AuthWeb';
import { oidcWebReset } from '../../../app/oidc-web/actions';

interface Prop {
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "100%",
    },
    page: {
        display: "flex",
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
    },
    field: {
        textAlign: 'center',
        width: "100%",
        color: theme.palette.text.secondary,
    },
    item: {
        marginTop: theme.spacing(2),
        width: "100%",
    },
    itemHalf: {
        marginTop: theme.spacing(2),
        width: "33%",
    },
  }));

function Oidc({ }: Prop) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const style = useStyles()
    const [flow, setFlow] = useState<string | undefined>(undefined)
    const handleVoiceOTP = (request: NotificationPayload & {submitButton: string}) => {
        if(request.submitButton === 'OTP') dispatch(oidcVoiceOtpNotify({email: request.email!, ...request}))
        if(request.submitButton === 'WEB') dispatch(authWebSetup({email: request.email!, ...request}))
        setFlow(request.submitButton)
    }
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const oidcPayload: OIDCPayload = {
            email: urlParams.get('email') || '',
            client_id: urlParams.get('client_id') || '',
            redirect_uri: urlParams.get('redirect_uri') || '',
            next: urlParams.get('next') || '',
            scope: urlParams.get('scope') || '',
            response_type: urlParams.get('response_type') as OIDCPayload['response_type'] || 'token',
            response_mode: urlParams.get('response_mode') as OIDCPayload['response_mode'] || 'query',
            state: urlParams.get('state') || '',
            nonce: urlParams.get('nonce') || '',
            code_challenge_method: urlParams.get('code_challenge_method') as OIDCPayload['code_challenge_method'] || undefined,
            code_challenge: urlParams.get('code_challenge') || undefined,
        };
        dispatch(oidcVoiceOtpStart(oidcPayload))
        if(!!oidcPayload.email) setTimeout(()=>handleVoiceOTP({service_id: "VOICEME", submitButton: "WEB", email: oidcPayload.email!}), 300)
    }, [dispatch])
    const okComplete = () => {
        dispatch(oidcWebReset())
        dispatch(oidcVoiceOtpReset())
        navigate('/')
    }
    switch(flow) {
        case 'OTP':
            return (<OidcOtp/>);
        case 'WEB':
            return (<AuthWeb okComplete={okComplete} okOnError={okComplete}/>);
        default:
        return (
            <FormEmailService hideSubmit={true} onClick={handleVoiceOTP}>
                <Button className={style.itemHalf} variant='outlined' type="submit">OTP</Button>
                <div className={style.itemHalf} ></div>
                <Button className={style.itemHalf} variant='outlined' type="submit">WEB</Button>
            </FormEmailService>
        );
    }
}

export default Oidc;

import { createSlice } from '@reduxjs/toolkit';
import { SignupStore } from './types';
import { confirmPostProcessed, enrollGetProcessed, enrollPostProcessed, signupPostClear, signupPostProcessed, signupPostTrigger } from '../api/actions';

const initialState: SignupStore = {
    status: "signup"
};

export const signup = createSlice({
  name: 'signup',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(signupPostClear, (state, action) => {
      state.status = "signup"
      state.signupPayload = undefined
    })
    .addCase(signupPostTrigger, (state, action) => {
      state.status = "loading"
      state.signupPayload = action.payload
    })
    .addCase(signupPostProcessed, (state, action) => {
      state.status = "confirm"
    })
  },
});

export default signup.reducer;

import { ActionReducerMapBuilder, createAction } from "@reduxjs/toolkit";
import { ArrayResponse, AuthGetRequest, AuthGetResponse, AuthorizePayload, CallApiPayload, ConfigEnvironment, ConfigureGetPayload, ConfigureGetResponse, ConfirmPayload, DocumentRequest, DocumentResponse, GenericError, M2MAuthorize, NotificationDescriptor, NotificationPayload, NotificationResponse, OIDCPayload, PartnerList, ProfilePayment, ProfilePaymentResponse, SignupPartnerGetPayload, SignupPartnerPostPayload, SignupPayload, VoicemeToken } from "./types";
import { APIStore } from "./store";

export const callApiStart = createAction<CallApiPayload>("call-api/start");
export const apiToken = createAction<VoicemeToken>("call-api/token");
export const logout = createAction("call-api/logout");

export const configEndpoint = createAction<{environments: ConfigEnvironment[]}>("call-api/config");
export const configFailed = createAction<GenericError>("call-api/failed");
export const configFlowSetup = createAction<{state: 'idle' | 'start' | 'progress'}>("call-api/flow/config");

export const INIT = createAction("voiceme/init");

type BuilderActionType = (builder: ActionReducerMapBuilder<APIStore>) => ActionReducerMapBuilder<APIStore>

function createAPIActions<R, E = void>(name: string) {
    const clear = createAction("api/" + name + "/clear")
    const trigger = createAction<R>("api/" + name + "/trigger")
    const pass = createAction<E>("api/" + name + "/processed")
    const fail = createAction<GenericError>("api/" + name + "/failed")
    return {
        clear: clear,
        trigger:trigger,
        pass: pass,
        fail: fail,
        builder: (builder: ActionReducerMapBuilder<APIStore>): ActionReducerMapBuilder<APIStore> => 
            builder.addCase(trigger, (state, action) => {
                state.apiCall["last"] = state.apiCall[name] = {
                    request: action.payload,
                    response: undefined,
                    error: undefined,
                }
            }).addCase(clear, (state, action) => {
                state.apiCall[name] = {
                    request: undefined,
                    response: undefined,
                    error: undefined,
                }
            }).addCase(pass, (state, action) => {
                state.apiCall["last"] = state.apiCall[name] = {
                    ...state.apiCall[name],
                    response: action.payload,
                }
            }).addCase(fail, (state, action) => {
                state.apiCall["last"] = state.apiCall[name] = {
                    ...state.apiCall[name],
                    response: undefined,
                    error: action.payload,
                }
            })
        }
}
// API Call
export const { clear: authorizeGetClear, trigger: authorizeGetTrigger, pass: authorizeGetProcessed, fail: authorizeGetFailed, builder: authorizeGetBuilding } = createAPIActions<AuthGetRequest, AuthGetResponse>("authorizeGet");
export const { clear: authorizePostClear, trigger: authorizePostTrigger, pass: authorizePostProcessed, fail: authorizePostFailed, builder: authorizePostBuilding } = createAPIActions<AuthorizePayload, VoicemeToken>("authorizePost");
export const { clear: configureGetClear, trigger: configureGetTrigger, pass: configureGetProcessed, fail: configureGetFailed, builder: configureGetBuilding } = createAPIActions<ConfigureGetPayload, ConfigureGetResponse>("configureGet");
export const { clear: documentGetClear, trigger: documentGetTrigger, pass: documentGetProcessed, fail: documentGetFailed, builder: documentGetBuilding } = createAPIActions<void, ArrayResponse<DocumentResponse>>("documentGet");
export const { clear: documentPostClear, trigger: documentPostTrigger, pass: documentPostProcessed, fail: documentPostFailed, builder: documentPostBuilding } = createAPIActions<DocumentRequest, DocumentResponse>("documentPost");
export const { clear: documentUserDeleteClear, trigger: documentUserDeleteTrigger, pass: documentUserDeleteProcessed, fail: documentUserDeleteFailed, builder: documentUserDeleteBuilding } = createAPIActions<string, void>("documentUserDelete");
export const { clear: documentUserGetClear, trigger: documentUserGetTrigger, pass: documentUserGetProcessed, fail: documentUserGetFailed, builder: documentUserGetBuilding } = createAPIActions<DocumentRequest, DocumentResponse>("documentUserGet");
export const { clear: documentUserPatchClear, trigger: documentUserPatchTrigger, pass: documentUserPatchProcessed, fail: documentUserPatchFailed, builder: documentUserPatchBuilding } = createAPIActions<DocumentRequest, DocumentResponse>("documentUserPatch");
export const { clear: m2mAuthorizeClear, trigger: m2mAuthorizeTrigger, pass: m2mAuthorizeProcessed, fail: m2mAuthorizeFailed, builder: m2mAuthorizeBuilding } = createAPIActions<M2MAuthorize, VoicemeToken>("m2mAuthorize");
export const { clear: notificationGetClear, trigger: notificationGetTrigger, pass: notificationGetProcessed, fail: notificationGetFailed, builder: notificationGetBuilding } = createAPIActions<NotificationDescriptor, NotificationResponse>("notificationGet");
export const { clear: notificationPostClear, trigger: notificationPostTrigger, pass: notificationPostProcessed, fail: notificationPostFailed, builder: notificationPostBuilding } = createAPIActions<NotificationPayload, NotificationResponse>("notificationPost");
export const { clear: oidcRedirectClear, trigger: oidcRedirectTrigger, pass: oidcRedirectProcessed, fail: oidcRedirectFailed, builder: oidcRedirectBuilding } = createAPIActions<OIDCPayload, {location: string}>("oidcRedirect");
export const { clear: oidcRedirectPostClear, trigger: oidcRedirectPostTrigger, pass: oidcRedirectPostProcessed, fail: oidcRedirectPostFailed, builder: oidcRedirectPostBuilding } = createAPIActions<OIDCPayload, {location: string}>("oidcRedirectPost");
export const { clear: partnerGetClear, trigger: partnerGetTrigger, pass: partnerGetProcessed, fail: partnerGetFailed, builder: partnerGetBuilding } = createAPIActions<void, PartnerList>("partnerGet");
export const { clear: profilePaymentClear, trigger: profilePaymentTrigger, pass: profilePaymentProcessed, fail: profilePaymentFailed, builder: profilePaymentBuilding } = createAPIActions<ProfilePayment, ProfilePaymentResponse>("profilePayment");
export const { clear: signupPartnerClear, trigger: signupPartnerTrigger, pass: signupPartnerProcessed, fail: signupPartnerFailed, builder: signupPartnerBuilding } = createAPIActions<SignupPartnerPostPayload>("signupPartnerPost");
export const { clear: signupPartnerGetClear, trigger: signupPartnerGetTrigger, pass: signupPartnerGetProcessed, fail: signupPartnerGetFailed, builder: signupPartnerGetBuilding } = createAPIActions<SignupPartnerGetPayload>("signupPartnerGet");
export const { clear: signupPostClear, trigger: signupPostTrigger, pass: signupPostProcessed, fail: signupPostFailed, builder: signupPostBuilding } = createAPIActions<SignupPayload>("signupPost");
export const { clear: confirmPostClear, trigger: confirmPostTrigger, pass: confirmPostProcessed, fail: confirmPostFailed, builder: confirmPostBuilding } = createAPIActions<ConfirmPayload, VoicemeToken>("confirmPost");
export const { clear: enrollGetClear, trigger: enrollGetTrigger, pass: enrollGetProcessed, fail: enrollGetFailed, builder: enrollGetBuilding } = createAPIActions<AuthGetRequest, AuthGetResponse>("enrollGet");
export const { clear: enrollPostClear, trigger: enrollPostTrigger, pass: enrollPostProcessed, fail: enrollPostFailed, builder: enrollPostBuilding } = createAPIActions<AuthorizePayload>("enrollPost");

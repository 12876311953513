import { MouseEventHandler } from 'react';
import { Children } from '../../type.d';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import VoicemeLoading from '../../assets/images/voiceme-loading.svg'

interface Props extends Children {
    image?: string
    title?: string
    message?: string
    subMessage?: string
    onClick?: MouseEventHandler<HTMLButtonElement>
}

const useStyles = makeStyles((theme) => ({
    loader: {
        textAlign: "center",
    }, 
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    }
}));


function Loader({
        title = "Operation in progress!",
        message = "please wait",
        subMessage = "" ,
        onClick,
        children,
    }: Props) {
    const style = useStyles()
    return (
        <div className={style.container}>
            {/* <CircularProgress className={style.loader}></CircularProgress> */}
            <img src={VoicemeLoading}/>
        </div>
    );
}

export default Loader;

import { createSlice } from '@reduxjs/toolkit';
import { EnrollStore } from './types';
import { enrollGetClear, enrollGetFailed, enrollGetProcessed, enrollPostFailed, enrollPostProcessed } from '../api/actions';

const initialState: EnrollStore = {
    status: "sentence"
};

export const enroll = createSlice({
  name: 'enroll',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(enrollGetClear, (state, action) => {
      state.status = "sentence"
      state.sentencePayload = undefined
      state.enrollPayload = undefined
    })
    .addCase(enrollGetProcessed, (state, action) => {
      state.status = "enroll"
      state.sentencePayload = action.payload
      state.enrollPayload = undefined
    })
    .addCase(enrollGetFailed, (state, action) => {
      state.status = "sentence"
      state.sentencePayload = undefined
      state.enrollPayload = undefined
    })
    .addCase(enrollPostFailed, (state, action) => {
      state.status = "sentence"
      state.enrollPayload = undefined
    })
    .addCase(enrollPostProcessed, (state, action) => {
      state.status = "success"
    })
  },
});

export default enroll.reducer;

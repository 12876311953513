import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { createEpicMiddleware, combineEpics, Epic } from "redux-observable";
import apiStore from './api/reducer';
import routerStore from './router/reducer';
import partnerSignup from './partner-signup/reducer';
import sidebarStore from './sidebar/reducer';
import documentStore from './document/reducer';
import signup from './signup/reducer';
import { callApiProcessing, epicAuthorizeGetTrigger, epicAuthorizePostTrigger, epicConfigEndpoint, epicConfigureGetTriggerTrigger, epicConfirmPost, epicConfirmTrigger, epicDocumentGetTrigger, epicDocumentPostProcessed, epicDocumentPostTrigger, epicDocumentUserDeleteProcessed, epicDocumentUserDeleteTrigger, epicDocumentUserGetTrigger, epicDocumentUserPatchTrigger, epicEnrollGetTrigger, epicEnrollPostTrigger, epicM2mAuthorizeProcessed, epicM2mAuthorizeTrigger, epicNotificationAfterSign, epicNotificationGetTrigger, epicNotificationPostTrigger, epicNotificationProcessed, epicOidcRedirectPostTrigger, epicOidcRedirectTrigger, epicProfilePaymentGetTrigger, epicSignupPartnerGetTrigger, epicSignupPartnerPaymentUrlTrigger, epicSignupPartnerTrigger, epicSignupTrigger } from './api/epic';
import { epicSignupPartnerDataFill, epicSignupPartnerFlowDATAtoREGISTER, epicSignupPartnerFlowFail, epicSignupPartnerFlowOTPtoDATA, epicSignupPartnerFlowPayment, epicSignupPartnerOTPFill } from './partner-signup/epic';
import { epicProfileStartup } from './profile/epic';
import { epicOidcVoiceOtp } from './oidc-otp/epic';
import authWeb from './auth-web/reducer';
import authOtp from './auth-otp/reducer';
import oidcWeb from './oidc-web/reducer';
import oidcOtp from './oidc-otp/reducer';
import enroll from './enroll/reducer';
import { epicAuthWebRequest, epicAuthWebSetup } from './auth-web/epic';
import { epicOidcWebSuccess } from './oidc-web/epic';
import { epicAuthOtpSetup } from './auth-otp/epic';

type EpicType = Epic<Action<any>, Action<any>, void, any>

const reducers = combineReducers({
  api: apiStore,
  authOtp: authOtp,
  authWeb: authWeb,
  enroll: enroll,
  document: documentStore,
  oidcOtp: oidcOtp,
  oidcWeb: oidcWeb,
  partnerSignup: partnerSignup,
  router: routerStore,
  sidebar: sidebarStore,
  signup: signup,
});

const rootEpic = combineEpics<Action<any>, Action<any>, void, any>(
  // API Call
  callApiProcessing as unknown as EpicType,
  epicSignupPartnerGetTrigger as unknown as EpicType,
  epicSignupPartnerTrigger as unknown as EpicType,
  epicSignupPartnerPaymentUrlTrigger as unknown as EpicType,
  epicProfilePaymentGetTrigger as unknown as EpicType,
  epicM2mAuthorizeTrigger as unknown as EpicType,
  epicM2mAuthorizeProcessed as unknown as EpicType,
  epicNotificationGetTrigger as unknown as EpicType,
  epicConfigureGetTriggerTrigger as unknown as EpicType,
  epicNotificationPostTrigger as unknown as EpicType,
  epicNotificationProcessed as unknown as EpicType,
  //
  epicSignupTrigger as unknown as EpicType,
  epicConfirmTrigger as unknown as EpicType,
  epicConfirmPost as unknown as EpicType,
  epicEnrollGetTrigger as unknown as EpicType,
  epicEnrollPostTrigger as unknown as EpicType,
  // epicLoginFlowMobileComplete as unknown as EpicType,
  // epicLoginFlowMobileFail as unknown as EpicType,
  // epicLoginProcessed as unknown as EpicType,
  // epicLoginGetTrigger as unknown as EpicType,
  // epicLoginTrigger as unknown as EpicType,
  // epicLoginPostProcessed as unknown as EpicType,
  // epicLoginPostFailed as unknown as EpicType,
  // epicLoginFlowMobileOtp as unknown as EpicType,
  // epicLoginFlowWebSentence as unknown as EpicType,
  // epicLoginFlowWebRecorderLoader as unknown as EpicType,
  // epicLoginFlowWebProcessed as unknown as EpicType,
  // epicLoginFlowWebNoMoreRetry as unknown as EpicType,
  // epicLoginFlowWebNoMoreRetryOnFail as unknown as EpicType,
  // epicM2mAuthorizeComplete as unknown as EpicType,
  // epicApiToken as unknown as EpicType,
  epicConfigEndpoint as unknown as EpicType,
  epicSignupPartnerFlowOTPtoDATA as unknown as EpicType,
  epicSignupPartnerFlowDATAtoREGISTER as unknown as EpicType,
  epicSignupPartnerOTPFill as unknown as EpicType,
  epicSignupPartnerDataFill as unknown as EpicType,
  epicSignupPartnerFlowFail as unknown as EpicType,
  epicSignupPartnerFlowPayment as unknown as EpicType,
  epicProfileStartup as unknown as EpicType,
  epicDocumentGetTrigger as unknown as EpicType,
  epicDocumentUserGetTrigger as unknown as EpicType,
  epicDocumentUserPatchTrigger as unknown as EpicType,
  epicDocumentPostTrigger as unknown as EpicType,
  epicDocumentPostProcessed as unknown as EpicType,
  epicNotificationAfterSign as unknown as EpicType,
  epicDocumentUserDeleteTrigger as unknown as EpicType,
  epicDocumentUserDeleteProcessed as unknown as EpicType,
  epicOidcRedirectTrigger as unknown as EpicType,
  epicOidcRedirectPostTrigger as unknown as EpicType,
  epicAuthorizeGetTrigger as unknown as EpicType,
  epicAuthorizePostTrigger as unknown as EpicType,

  // OIDC Otp
  epicOidcVoiceOtp as unknown as EpicType,

  // OIDC Web
  epicOidcWebSuccess as unknown as EpicType,

  // AuthWeb
  epicAuthWebSetup as unknown as EpicType,
  epicAuthWebRequest as unknown as EpicType,

  // AuthOTP
  epicAuthOtpSetup as unknown as EpicType,
)

const epicMiddleware = createEpicMiddleware();

export const store = configureStore({
  reducer: reducers,
  middleware: [epicMiddleware],
  }
);

epicMiddleware.run(rootEpic);


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import { makeStyles } from '@material-ui/core';
import { Button, FormControl, FormLabel, TextField } from '@mui/material';
import { Form } from 'react-router-dom';

interface Prop {
    onClick: ({ config }: { config: string }) => void
}


const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "50%",
    },
    page: {
        display: "flex",
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
    },
    field: {
        textAlign: 'center',
        width: "100%",
        color: theme.palette.text.secondary,
    },
    item: {
        marginTop: theme.spacing(2),
        width: "100%",
    },
    itemHalf: {
        marginTop: theme.spacing(2),
        width: "33%",
    },
  }));

function Config({ onClick }: Prop) {
    const style = useStyles()
    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        const configField = form.elements.config;
        onClick({ config: configField.value })
    }
    return (
        <div className={style.page}>
            <Form className={style.form} onSubmit={handleSubmit}>
                <TextField className={style.item} id="config"  label="Please enter the configration" variant="outlined" type="text" color='primary' defaultValue="" />
                <div className={style.item} ></div>
                <Button className={style.itemHalf} variant='outlined' type="submit">Submit</Button>
            </Form>
        </div>
    );
}

export default Config;

import { ReactNode } from 'react';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { enrollGetClear } from '../../../app/api/actions';
import { useAppDispatch } from '../../../app/hooks';

interface Prop {
    onResetStatus?: () => void;  // Callback for resetting status
    children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    message: {
        marginBottom: theme.spacing(2),
        color: theme.palette.text.primary,
        textAlign: 'center',
    },
    button: {
        marginTop: theme.spacing(2),
        width: '100%',
        maxWidth: '300px',
    },
}));

function EnrollComplete({ onResetStatus, children }: Prop) {
    const style = useStyles();
    const dispatch = useAppDispatch()

    const handleResetStatus = () => {
        // Callback function for resetting status
        dispatch(enrollGetClear())
        onResetStatus?.();
    };

    return (
        <div className={style.container}>
            <Typography variant="h5" className={style.message}>
                Biometry Updated
            </Typography>
            <Button
                className={style.button}
                variant="contained"
                color="secondary"
                onClick={handleResetStatus}
            >
                Reset Status
            </Button>
            {children}
        </div>
    );
}

export default EnrollComplete;

import { AddressPayload, SignupPartnerPostPayload } from '../../app/api/types';
import { Form } from 'react-router-dom';
import { Button, InputLabel, TextField } from '@mui/material';
import { Checkbox, FormControlLabel, Grid, Input, Paper, makeStyles } from '@material-ui/core';
import { ReactNode, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

interface Prop {
    onClick: (partnerData: SignupPartnerPostPayload) => void,
    onCancel: () => void,
    data: SignupPartnerPostPayload,
    children?: ReactNode,
}

const useStyles = makeStyles((theme) => ({
    page: {
        display: "flex",
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
    },
    form: {
        flexWrap: "wrap",
        width: "70vw",
    },
    field: {
        textAlign: 'center',
        width: "100%",
        color: theme.palette.text.secondary,
    },
    item: {
        marginTop: theme.spacing(0),
        width: "100%",
    },
    itemHalf: {
        marginTop: theme.spacing(0),
        width: "33%",
    },
    itemLabel: {
        size: "small",
    },
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function Address({prefix, label, hideable, defaultValue} : {prefix: string, label: string, hideable: boolean, defaultValue?: AddressPayload}) {
    const [hide, setHide] = useState(hideable)
    useEffect(() => {

    }, [hide])
    const style = useStyles()
    return (<>
        <Grid container spacing={2}>
            <Grid item xs={10} > <Typography>{label}</Typography> </Grid>
            <Grid item xs={2} hidden={!hideable} > 
                <FormControlLabel control={<Checkbox id={prefix + "_same"} checked={hide} onChange={() => setHide(!hide)}/>} label="Same" />
            </Grid>
            <Grid item xs={12} hidden={hide}>
                <TextField label="Line 1" defaultValue={defaultValue?.line1} className={style.item} id={prefix + '_line1'} type="text" color='primary' />
            </Grid>
            <Grid item xs={12} hidden={hide}>
                <TextField label="Line 2" defaultValue={defaultValue?.line2} className={style.item} id={prefix + '_line2'} type="text" color='primary' />
            </Grid>
            <Grid item xs={3}  hidden={hide}>
                <TextField label="Postal Code" defaultValue={defaultValue?.postal_code} className={style.item} id={prefix + '_postal_code'} type="text" color='primary' />
            </Grid>
            <Grid item xs={6} hidden={hide}>
                <TextField label="City" defaultValue={defaultValue?.city} className={style.item} id={prefix + '_city'} type="text" color='primary' />
            </Grid>
            <Grid item xs={3} hidden={hide}>
                <TextField label="State" defaultValue={defaultValue?.state} className={style.item} id={prefix + '_state'} type="text" color='primary' />
            </Grid>
            <Grid item xs={12} hidden={hide}>
                <TextField label="Country" defaultValue={defaultValue?.country} className={style.item} id={prefix + '_country'} type="text" color='primary' />
            </Grid>
        </Grid>
    </>)
}
function FormPartner({ onClick, onCancel, data, children } : Prop) {
    const style = useStyles()
    const [value, setValue] = useState(0);
    const handleSubmit = (event: any, is_private: boolean) => {
        event.preventDefault();
        const form = event.currentTarget;
        const company_name = form.elements?.company_name?.value;
        const full_name_referent = form.elements?.full_name_referent?.value;
        const role_referent = form.elements?.role_referent?.value;
        const mobile_referent = form.elements?.mobile_referent?.value;
        const email = form.elements?.email?.value;
        const tax_id = form.elements?.tax_id?.value;
        const pi = form.elements?.pi?.value;
        const registered_office_line1 = form.elements?.registered_office_line1?.value;
        const registered_office_line2 = form.elements?.registered_office_line2?.value;
        const registered_office_postal_code = form.elements?.registered_office_postal_code?.value;
        const registered_office_city = form.elements?.registered_office_city?.value;
        const registered_office_state = form.elements?.registered_office_state?.value;
        const registered_office_country = form.elements?.registered_office_country?.value;
        const billing_address_same = form.elements?.billing_address_same?.checked;
        const billing_address_line1 = form.elements?.billing_address_line1?.value;
        const billing_address_line2 = form.elements?.billing_address_line2?.value;
        const billing_address_postal_code = form.elements?.billing_address_postal_code?.value;
        const billing_address_city = form.elements?.billing_address_city?.value;
        const billing_address_state = form.elements?.billing_address_state?.value;
        const billing_address_country = form.elements?.billing_address_country?.value;
        onClick({ 
            partner:{
                email,
                name: company_name,
                is_private,
                full_name_referent,
                role_referent,
                mobile_referent,
                tax_id,
                pi,
                registered_office: {
                    line1: registered_office_line1,
                    line2: registered_office_line2,
                    postal_code: registered_office_postal_code,
                    city: registered_office_city,
                    state: registered_office_state,
                    country: registered_office_country,
                },
                billing_address: billing_address_same ? undefined : {
                    line1: billing_address_line1,
                    line2: billing_address_line2,
                    postal_code: billing_address_postal_code,
                    city: billing_address_city,
                    state: billing_address_state,
                    country: billing_address_country,
                },
            }
        })
    }
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <>
            <CssBaseline />
            <div className={style.page}>
                <div className={style.form}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Private" />
                            <Tab label="Company" />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Form className={style.form} onSubmit={(e) => handleSubmit(e, true)}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <TextField label="Full Name" defaultValue={data.partner?.name} className={style.item} id="company_name" type="text" color='primary' />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField label="Mobile" defaultValue={data.partner?.mobile_referent} className={style.item} id="mobile_referent" type="text" color='primary' />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField label="Email" defaultValue={data.partner?.email} className={style.item} id="email" type="text" color='primary'  />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField label="TAX ID" defaultValue={data.partner?.tax_id} className={style.item} id="tax_id" type="text" color='primary'  />
                                </Grid>
                                <Grid item xs={12} >
                                    <Address prefix={'registered_office'} defaultValue={data.partner?.registered_office} label='Registered Office Address' hideable={false}/>
                                </Grid>
                                <Grid item xs={12} >
                                    <Address prefix={'billing_address'} defaultValue={data.partner?.billing_address} label='Billing Address' hideable={true}/>
                                </Grid>
                                <Grid item xs={6} >
                                    <Button className={style.itemHalf} variant='outlined' type="submit">Submit</Button>
                                </Grid>
                                <Grid item xs={6} >
                                    <Button className={style.itemHalf} variant='outlined' type="button" onClick={onCancel}>Cancel</Button>
                                </Grid>
                                <Grid item xs={6} >
                                     {children}
                                </Grid>
                            </Grid>
                        </Form>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Form className={style.form} onSubmit={(e) => handleSubmit(e, false)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <TextField label="Company Name" defaultValue={data.partner?.name} className={style.item} id="company_name" type="text" color='primary' />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField label="Full Name Referent" defaultValue={data.partner?.full_name_referent} className={style.item} id="full_name_referent" type="text" color='primary'  />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField label="Role Referent" defaultValue={data.partner?.role_referent} className={style.item} id="role_referent" type="text" color='primary' />
                            </Grid>
                                <Grid item xs={6} >
                                    <TextField label="Mobile Referent" defaultValue={data.partner?.mobile_referent} className={style.item} id="mobile_referent" type="text" color='primary' />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField label="Email" defaultValue={data.partner?.email} className={style.item} id="email" type="text" color='primary'  />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField label="VAT Code" defaultValue={data.partner?.pi} className={style.item} id="pi" type="text" color='primary' />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField label="TAX ID" defaultValue={data.partner?.tax_id} className={style.item} id="tax_id" type="text" color='primary'  />
                                </Grid>
                                <Grid item xs={12} >
                                    <Address prefix={'registered_office'} defaultValue={data.partner?.registered_office} label='Registered Office Address' hideable={false}/>
                                </Grid>
                                <Grid item xs={12} >
                                    <Address prefix={'billing_address'} defaultValue={data.partner?.billing_address} label='Billing Address' hideable={true}/>
                                </Grid>
                                <Grid item xs={6} >
                                    <Button className={style.itemHalf} variant='outlined' type="submit">Submit</Button>
                                </Grid>
                                <Grid item xs={6} >
                                    <Button className={style.itemHalf} variant='outlined' type="button" onClick={onCancel}>Cancel</Button>
                                </Grid>
                                <Grid item xs={6} >
                                     {children}
                                </Grid>
                            </Grid>
                        </Form>
                    </CustomTabPanel>
                </div>
            </div>
        </>
    );
}

export default FormPartner;



import { Action } from "redux";
import { Observable, merge, of } from "rxjs";
import { catchError, filter,  ignoreElements,  map,  switchMap, take, tap, timeout, withLatestFrom } from "rxjs/operators";
import { RootState } from "../store";
import { oidcRedirectPostProcessed, oidcRedirectPostTrigger, oidcRedirectProcessed, oidcRedirectTrigger } from "../api/actions";
import { authWebComplete } from "../auth-web/actions";
import { oidcWebReset } from "./actions";
import { oidcError, oidcSuccess } from "../oidc-otp/actions";
 
export const _raiseError = (error: any) => {
    if (error.name === 'TimeoutError') {
        return of(oidcError('timeout'));
    }
    return of(oidcError('unknown'));
}

export const _epicCatchPostRedirect_ShipSuccess = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(oidcRedirectPostProcessed.match),
        timeout(5000),
        take(1),
        withLatestFrom(state$),
        map(() =>oidcSuccess()),
        catchError(() => of(oidcSuccess())),
    )

export const _epicCatchGetRedirect_ShipPostRedirect = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(oidcRedirectProcessed.match),
        timeout(5000),
        take(1),
        withLatestFrom(state$),
        switchMap(([, state]) =>
            merge(
                of(oidcRedirectPostTrigger(state?.oidcOtp?.oidcParms!)),
                _epicCatchPostRedirect_ShipSuccess(action$, state$),
            )
        ),
        catchError(_raiseError),
    )

export const epicOidcWebSuccess = (action$: Observable<Action>, state$: Observable<RootState>) =>
    action$.pipe(
        filter(authWebComplete.match),
        withLatestFrom(state$),
        filter(([, state]) => !!state.api.api),
        filter(([, state]) => !!state?.oidcOtp?.oidcParms),
        switchMap(([action, state]) => {
            return merge(
                    of(oidcRedirectTrigger(state?.oidcOtp?.oidcParms!)),
                    _epicCatchGetRedirect_ShipPostRedirect(action$, state$),
                )
            }
        )
    );

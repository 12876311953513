import { useEffect, useState } from 'react';
import VideoPlayer from './VideoPlayer';
import { IRecording, RecorderInstance } from './Recorder';
import VoicemeButton from '../../atoms/VoicemeButton';
import voicemeBouble from '../../../assets/images/voiceme-bouble.svg'
import voicemeBoubleRed from '../../../assets/images/voiceme-bouble-red.svg'
import { Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@mui/material';
import { Image, Opacity } from '@mui/icons-material';
import { AuthorizePayload } from '../../../app/api/types';

interface StyleProps {
    isRecordingStart: boolean
}
const useStyles = makeStyles((theme) => ({
    sentenceContainer: {
        textAlign: "center",
        position: 'absolute',
        width: '100%',
        paddingTop: '0%',
        backgroundColor: '#5358f7AF',
        color: "white",
    },
    recorderContainer: {
        position: 'relative',
        overflow: 'hidden',
        height: '50vw',
        width: '50vw',
    },
    videoContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    bubleContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    bubleButton: (props: StyleProps) => ({
        bottom: '20%',
        left: '40%',
        width: '15%',
        paddingTop: '100%',
    }),
    bubleButtonImage: {
        width: '100%',
        height: '100%',
    },
}));

interface Props {
    sentence?: string
    action: (payload: AuthorizePayload) => void
    cancel: () => void
    attempt?: number
    duration?: number
}


const VoicemeRecorder = ({ sentence, action, attempt, duration = 5, cancel = () => { } }: Props) => {
    const [videoStream, setVideoStream] = useState<MediaStream>();
    const [isRecordingStart, setIsRecordingStart] = useState<boolean>(false)

    const authorizeVoiceme = async ({ video, videoType, audio, audioType }: IRecording) => {
        action({
            email: "sicilia.cristian@gmail.com",
            session_id: "",
            audio: {
                type: audioType,
                dataURL: audio
            },
            video: {
                type: videoType,
                dataURL: video
            }
        })
    }
    const startRecording = (recordingDuration: number) => {
        if (videoStream) {
            if (isRecordingStart) {
                // console.log("Recording in progress");
            } else {
                RecorderInstance.startVideoRecording();
                // console.log("Recording started");
                setIsRecordingStart(true);
                setTimeout(() => {
                    requestRecording();
                }, recordingDuration * 1000);
            }
        } else {
            alert("Allow your browser to use your camera and microphone");
        }
    }

    const requestRecording = async () => {
        const recording = await RecorderInstance.requestRecording();
        // console.log("Recording stopped");
        setIsRecordingStart(false);
        authorizeVoiceme(recording);
    }

    const getPermissions = async () => {
        // console.log("CREATE STREAM")
        const videoStream = await RecorderInstance.getVideoStream();
        setVideoStream(videoStream);
    }

    const onClickCancel = () => {
        cancel()
    }

    useEffect(() => {
        if ("MediaRecorder" in window) {
            getPermissions().catch(console.error);
        } else {
            console.log(`The MediaRecorder API is not supported in your browser`);
        }
    }, [])
    const style = useStyles({ isRecordingStart })
    return (<>
        <div className={style.recorderContainer}>
            {videoStream && <VideoPlayer className={style.videoContainer} srcObject={videoStream}>
            </VideoPlayer>
            }
            <div className={style.bubleContainer}>
                <Button className={style.bubleButton}
                    onClick={() => startRecording(duration)}>
                    <img className={style.bubleButtonImage} src={isRecordingStart ? voicemeBoubleRed : voicemeBouble} />
                </Button>
            </div>
            {sentence && (<div className={style.sentenceContainer}>
                <Typography variant="h6">
                    Look at the webcam and read:
                </Typography>
                <Typography variant="h5">
                    {sentence}
                </Typography>
            </div>)}
        </div>
        {videoStream && <VideoPlayer  style={{opacity:0.001}} srcObject={videoStream} />}
        </>
    )
}

export default VoicemeRecorder;

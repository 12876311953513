import { ChangeEvent, ReactNode, useState } from 'react';
import { Form } from 'react-router-dom';
import { Button, CssBaseline, TextField } from '@mui/material';
import { Grid, makeStyles } from '@material-ui/core';
import { SignupPartnerPostPayload } from '../../app/api/types';

interface Prop {
    onClick: (partnerData: SignupPartnerPostPayload) => void,
    children?: ReactNode,
    data: SignupPartnerPostPayload,
}

 

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "50%",
    },
    page: {
        display: "flex",
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
    },
    field: {
        textAlign: 'center',
        width: "100%",
        color: theme.palette.text.secondary,
    },
    item: {
        marginTop: theme.spacing(2),
        width: "100%",
        display: "flex",
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        '& input': {
            cursor: 'none',
            border: 'none',
            borderBottom: '1px solid #000', // Add a bottom border for separation
            fontSize: '24px', // Adjust the font size as needed
            lineHeight: '32px', // Adjust the line height as needed
        },
    },
    itemHalf: {
        marginTop: theme.spacing(2),
        width: "33%",
    },
  }));

function FormOTP({ onClick, children, data }: Prop) {
    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        const otp_email = form.elements.otp_1.value +
            form.elements.otp_2.value +
            form.elements.otp_3.value +
            form.elements.otp_4.value +
            form.elements.otp_5.value +
            form.elements.otp_6.value;
        onClick({ ...data, otp_email: otp_email })
    }

    const handleInput = (index: number) => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const inputValue = event.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        event.target.value = inputValue; // Update the input value
        if(inputValue.length <= 0) return
        const nextIndex = index + 1;
        if (nextIndex <= 6) {
            const nextInput = document.getElementById(`otp_${nextIndex}`);
            if (nextInput) {
                nextInput.focus();
            }
        }
    };
    const style = useStyles()
    return (
        <div className={style.page}>
            <CssBaseline />
            <Form className={style.form} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                {[1, 2, 3, 4, 5, 6].map((index) => (
                        <Grid item xs={2} key={index}>
                        <TextField
                            
                            className={style.item}
                            id={`otp_${index}`}
                            InputProps={{
                            disableUnderline: true, // Remove the underline
                            }}
                            inputProps={{
                                maxLength: 1,
                            }}
                            onChange={handleInput(index)}
                        />
                        </Grid>
                    ))}   
                    <Grid item xs={4} key={9}>
                        <Button className={style.item} variant='outlined' type="submit">Submit</Button>
                    </Grid>
                    <Grid item xs={8} key={10}>
                        {children}
                    </Grid>
                </Grid>
            </Form>
        </div>
    );
}

export default FormOTP;
